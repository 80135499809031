<template>
  <div class="md:px-[5.625vw]">
    <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
    <div class="md:aspect-w-16 md:aspect-h-9">
      <div @click="openModal()"
           class="cursor-pointer block md:max-h-[calc(100vh-5vw)] group overflow-hidden shadow-m mx-auto md:rounded-[1.25vw] relative"
           :style="{ clipPath: 'content-box' }"
      >
        <div :id="`video-player-background-${videoPlaylistId}`"
             class="object-cover w-full h-full"
        />
        <div class="absolute inset-0 flex items-center translate-y-[-10vw] md:translate-y-0 justify-center duration-300 scale-75 md:scale-110 md:opacity-0 md:group-hover:opacity-100">
          <ButtonPlay />
        </div>
        <div class="video-bottom-overlay flex-wrap md:flex-nowrap md:absolute md:bottom-0 flex justify-center w-full bg-red md:rounded-b-[1.25vw] px-[6vw] md:px-0 py-[5vw] md:py-0"
             :style="`--video-text-offset: ${heightOffset};`"
        >
          <div class="font-extrabold w-full md:w-auto text-center text-white font-work text-[3.5vw] leading-[5vw] mb-[4vw] md:mb-0">
            {{ subtitle }}
          </div>
          <SvgBadgeYoutube v-if="badges.youtube"
                           class="h-[6vw]"
          />
          <SvgBadgeNetflix v-if="badges.netflix"
                           class="h-[6vw] ml-[8vw] md:ml-0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useState } from '#imports';
import { computed } from 'vue';
import { screenSize } from '~/composables/screen-size';
import { useEmbedJwplayer } from '~/composables/use-embed-jwplayer';
import { useVideoPlaylistUrl } from '~/composables/use-video-playlist-url';

type SectionVideosFeatureProps = {
  videoPlaylistId: string;
  subtitle: string;
  badges: {
    youtube: boolean;
    netflix: boolean;
  };
};

const props = defineProps<SectionVideosFeatureProps>();

const isOpen = useState('video-popup', () => false);
const playlist = useState('video-playlist', () => '');
const size = screenSize();

const heightOffset = computed(() => {
  // 0.1125 is the sum of px on desktop container
  const paddingOffset = - size.value.width * 0.1125;

  // max-h-[calc(100vh-5vw)], this is the -5vw
  const heightOffset = - size.value.width * 0.05;

  if ((size.value.width + paddingOffset) / (size.value.height + heightOffset) > 16 / 9) {
    // value is the ratio of the video size that should have been, vs the one it is
    return 1 / ((size.value.width + paddingOffset) / (size.value.height + heightOffset) / (16 / 9));
  } else {

    return 1;
  }
});

const { playlistUrl } = useVideoPlaylistUrl(props.videoPlaylistId);

function embedJwplayer() {
  if (playlistUrl.value !== '') {
    // @ts-expect-error (jwplayer does not provide types)
    window.jwplayer(`video-player-background-${props.videoPlaylistId}`).setup({
      'controls': false,
      'repeat': true,
      'nextUpDisplay': false,
      'displaydescription': false,
      'stretching': 'fill',
      'mute': true,
      'displaytitle': false,
      'playlist': playlistUrl.value
    });
  }
}

useEmbedJwplayer(embedJwplayer);

function openModal() {
  isOpen.value = true;
  playlist.value = props.videoPlaylistId;
}
</script>

<style>
:root {
    --video-text-offset: 1;
}

@screen md {
  .video-bottom-overlay {
    padding: calc(var(--video-text-offset) * 2.5vw) 1vw;
  }

  .video-bottom-overlay > :not([hidden]) ~ :not([hidden]) {
        margin-left: calc(var(--video-text-offset) * 2.5vw);
  }

  .video-bottom-overlay svg {
    height: calc(var(--video-text-offset) * 2vw);
  }

  .video-bottom-overlay div {
    font-size: calc(var(--video-text-offset) * 1.875vw);
    line-height: calc(var(--video-text-offset) * 2.125vw);
  }
}
</style>